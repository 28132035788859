
import { defineComponent } from 'vue';
import NProgress from 'nprogress';

interface Data {
  startAmount: number;
  endAmount: number;
  duration: number;
  prefix: string;
  suffix: string;
  separator: string;
  decimalSeparator: string;
  decimals: number;
  autoinit: boolean;
  showAlert: boolean;
  isShowingAlert: boolean;
}

export default defineComponent({
  name: 'Demo',
  data(): Data {
    return {
      startAmount: 0,
      endAmount: 2021,
      duration: 3,
      prefix: '',
      suffix: '',
      separator: '',
      decimalSeparator: '',
      decimals: 0,
      autoinit: false,
      showAlert: false,
      isShowingAlert: false,
    };
  },
  mounted() {
    NProgress.done();
    this.$refs.counter.start();
  },
  computed: {
    vue3Tag() {
      return `
<vue3-autocounter ref="counter" :startAmount="${this.startAmount}" :endAmount="${this.endAmount}" :duration="${this.duration}" prefix="${this.prefix}" suffix="${this.suffix}" separator="${this.separator}" decimalSeparator="${this.decimalSeparator}" :decimals="${this.decimals}" :autoinit="${this.autoinit}" ${this.showAlert ? '@finished="alert(`Counting finished!`)"' : ''}/>
      `;
    },
  },
  watch: {
    startAmount() {
      if (!this.startAmount) this.startAmount = 0;
    },
    endAmount() {
      if (!this.endAmount) this.endAmount = 0;
    },
    duration() {
      if (this.duration < 1) this.duration = 1;
    },
    decimals() {
      if (this.decimals < 0) this.decimals = 0;
      this.decimals = parseInt(this.decimals, 10);
    },
  },
  methods: {
    pause(): void {
      this.$refs.counter.pause();
    },
    resume(): void {
      this.$refs.counter.resume();
    },
    reset(): void {
      this.$refs.counter.reset();
    },
    start(): void {
      this.isShowingAlert = false;
      this.$refs.counter.start();
    },
    onCountFinished(): void {
      if (this.showAlert) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.isShowingAlert = true;
      }
    },
  },
});
